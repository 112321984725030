import React from "react";
import "./HomePage.css";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
const HomePageMacedonian = ({geo}) => {
  const history = useHistory();
  const state = useSelector(state => state);
  
  window.onpopstate = e => {
    window.location.reload(false);

  }


  let urlPars = '?country='+geo.country_code.toLowerCase() + '&currency=' + geo.currency_code.toLowerCase() + '&language=' + geo.language.toLowerCase()+'&city=' + geo.city.toLowerCase();

  return (
    <div class="wrapper">
      <div class="header">
        <img
          alt="logo"
          style={{ height: "90%" }}
          src="https://e-menupull.b-cdn.net/logo_e-menu_big.png"
        />
      </div>

      <img
        alt="ad"
        class="demoImage"
        src="https://e-menupull.b-cdn.net/macedonian.png"
      />

      <div style={{ width: "90%", maxWidth: "20em", paddingTop: "1em" }}>
        Заштитете ги вашите клиенти, тргнете ги хартиените менија од масите со креирање на БЕСПЛАТНО QR дигитално мени. (Понудата важи до 30 Септември 2020)
              {/* Zastite gi vasite klienti, allow them to see your menu by scanning QR code or going to your e-menu link! */}
      <br></br> <br></br> 
      </div>
          <button class="btnStyle" onClick={() =>  window.location.href = "https://admin.e-menu.co.edit8.com/login"+urlPars}>Креирајте ново мени / Изменете постоечко</button>
          <button class="btnStyle"  onClick={() => history.push("/trendgastro_aerodrom")}>Видете како изгледа DEMO e-menu <br></br></button>
   
      {/* <div style={{ width: "90%", maxWidth: "20em", paddingTop: "1em" }}    onClick={() => props.history.push("/businessLogin")}>
              Login to My Account
      </div> */}
      {/* <button class="btnStyle"   onClick={() => history.push("/messenger")}>Пратете ни порака преку Messenger!</button> */}
     <button class="btnStyle" onClick={() => {   window.location.href = "https://m.me/restaurant.emenus" }}>Пратете ни порака преку Messenger!</button>
      <div
        style={{
          width: "90%",
          maxWidth: "20em",
          paddingTop: "1em",
          bottom: "1em",
          position: "fixed",
          fontSize: "0.5em",
        }}
      >
        Услови за користење & Приватност
      </div>
    </div>
  );
};

export default HomePageMacedonian;
