import React, { Suspense, lazy, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";

import thunk from "redux-thunk";

import { Provider, useStore } from "react-redux";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import * as serviceWorker from "./serviceWorker";
import Axios from "axios";
import HomePageMacedonian from "./HomePageMacedonian";
import LoaderInitial from "./LoaderInitial";
const NoQrCodeLandingComponent = React.lazy(() =>
  import("./NoQrCodeLandingComponent")
);
const HomePage = React.lazy(() => import("./HomePage"));
var constants = require("./constants.js");
const InvalidCodeComponent = lazy(() => import("./InvalidCodeComponent"));
const InfoModal = lazy(() => import("./InfoModal"));
const App = lazy(() => import("./App"));
let translate = (sentence, state) => {
  return state.translation[state.default_language]
    ? state.translation[state.default_language][sentence]
      ? state.translation[state.default_language][sentence]
      : sentence
    : null;
};
const initialState = {
  categories: [],
  API: constants.API,
  productInfoModal: { show: false, productData: {} },
  shoppingCart: [],
  search_filters: [],
  scale: 1,
  translation: {
    mk: { example: "пример", артикли: "", item: "артикл", ORDER: "Нарачај" },
  },
  translate: translate,
};

const langMap = {
  "A": "А",
  "B": "Б",
  "V": "В",
  "G": "Г",
  "D": "Д",
  
  "E": "Е",
  
  "Z": "З",
  "I": "И",
  "J": "Ј",
  "K": "К",
  "L": "Л",
  "Lj": "Љ",
  "M": "М",
  "N": "Н",
  "Nj": "Њ",
  "O": "О",
  "P": "П",
  "R": "Р",
  "S": "С",
  "T": "Т",
  
  "U": "У",
  "F": "Ф",
  "H": "Х",
  "C": "Ц",
  "CH": "Ч",
  "DZ": "Џ",
  "SH": "Ш",
  "a": "а",
  "b": "б",
  "v": "в",
  "g": "г",
  "d": "д",
  
  "e": "е",
 
  "z": "з",
  "i": "и",
  "j": "ј",
  "k": "к",
  "l": "л",
  "lj": "љ",
  "m": "м",
  "n": "н",
  "nj": "њ",
  "o": "о",
  "p": "п",
  "r": "р",
  "s": "с",
  "t": "т",
 
  "u": "у",
  "f": "ф",
  "h": "х",
  "c": "ц",
  "ch": "ч",
  "dz": "џ",
  "sh": "ш"
}
// function checifenglishkeyboard(ob,e){
//   re = /\d|\w|[\.\$@\*\\\/\+\-\^\!\(\)\[\]\~\%\&\=\?\>\<\{\}\"\'\,\:\;\_]/g;
// a = e.key.match(re);
// if (a == null){
//   // alert('Error 2:\nNon English keyboard layout is detected!\nSet the keyboard layout to English and try to fill out the field again.');
 
//   return false;
// }
// return true;
// } 
function reducer(state = initialState, action) {
  console.log("reducer", state, action);

  switch (action.type) {
    case "MODAL_PRODUCT_INFO":
      state = { ...state, productInfoModal: action.payload };
      break;

    case "PREPARE_MENU_START":

    case "ADD_PRODUCT_TO_SHOPPING_CART":
      // state.shoppingCart = [
      //   ...state.shoppingCart,
      //   JSON.parse(
      //     JSON.stringify({ ...action.payload, timestamp_added: Date.now() })
      //   ),
      // ];
      break;

    case "REMOVE_PRODUCT_FROM_SHOPPING_CART":
      state.shoppingCart = [
        ...state.shoppingCart.filter(
          (product) =>
            product.timestamp_added !== action.payload.timestamp_added
        ),
      ];

      break;

    // case "SEARCH_FOR_PRODUCTS":
    //   state.shoppingCart = [
    //     ...state.shoppingCart,
    //     JSON.parse(
    //       JSON.stringify({ ...action.payload, timestamp_added: Date.now() })
    //     ),
    //   ];

    //   break;
    case "SET_GEODATA":
     
      state.geoData = action.payload;
   
      break;
    case "SET_ACTIVE_MENU":
      if (action.payload.categories) {
        state = {
          ...state,
          owner_id: action.payload.owner_id,
          short_code: action.payload.short_code,
          categories: [...action.payload.categories],
          menuCurrency: action.payload.menuCurrency,
        };
      } else {
        state = {
          ...state,
          owner_id: action.payload.owner_id,
          short_code: action.payload.short_code,
           photos: action.payload.photos,
          type:action.payload.type,
          menuCurrency: action.payload.menuCurrency,
        };
      }
      localStorage.setItem("venue_owner_id", action.payload.owner_id);
      break;
    case "SEARCH":
      let catsCopy = [...state.categories];
      if (action.payload.length > 0) {
        for (let category of catsCopy) {
          category.show = false;
          category.strongMatch = false;
          for (let item of category.items) {
            item.show = false;
          }
        }

        state.categories = catsCopy;
      } else {
        for (let category of catsCopy) {
          category.show = true;
          category.strongMatch = false;
          for (let item of category.items) {
            item.show = true;
          }
        }
        state.categories = catsCopy;
        state.search_filters = [];
        break;
      }
      state.search_filters = action.payload.split(/(\s+)/);
      state.search_filters = state.search_filters.filter((filter) => {
        return filter !== "" && filter !== " " && filter !== "  ";
      });
      state.search_filters.forEach(
        (filter, index) => {
          let word = filter.toUpperCase()
          var newWord = ""
          for (var i = 0; i < word.length; i++) {
            let letter = word.charAt(i);
            console.log("shortcode:",action.shortCode)
            if ( langMap[letter] != undefined && action.shortCode == 'super_skopje') {
            newWord = newWord +  langMap[letter]
            } else {
              newWord = newWord + letter;
          }
          }
         return  (state.search_filters[index] = newWord)

        }
      );

      break;

    // case "NEW_DATA_FROM_SERVER":
    //   let menusWithAllAvailableLanguages = action.payload.menus;
    //   let phoneLanguage = action.payload.phoneLanguage;
    //   let defaultMenu = null;
    //   for (let oneLanguage of menusWithAllAvailableLanguages) {
    //     defaultMenu = oneLanguage;
    //     for (let category of defaultMenu.categories) {
    //       category.show = true;
    //       category.strongMatch = true;
    //       for (let item of category.items) {
    //         item.show = true;
    //       }
    //     }
    //   }
    //   for (let oneLanguage of menusWithAllAvailableLanguages) {

    //     for (let category of oneLanguage.categories) {
    //       category.show = true;
    //       category.strongMatch = true;
    //       for (let item of category.items) {
    //         item.show = true;
    //       }
    //     }
    //     if (oneLanguage.language === phoneLanguage)
    //     defaultMenu = oneLanguage;

    //   }

    //   state = {
    //     ...state,  ...defaultMenu , menusWithAllAvailableLanguages: menusWithAllAvailableLanguages
    //   }
    //   break;
    default:
      break;
  }
  // case "NEW_DATA_FROM_SERVER_OLD":
  //   let newState = action.payload;
  //   for (let category of newState.categories) {
  //     category.show = true;
  //     category.strongMatch = true;
  //     for (let item of category.items) {
  //       item.show = true;
  //     }
  //   }
  //   state = {
  //     ...state, ...newState, menuData: { menuCurrency:newState.menuCurrency?newState.menuCurrency:"MKD - Denar" }
  //   }
  //   break;
  // default:
  //   break;

  if (state.search_filters.length) {
    let categories = [...state.categories];
    for (let category of categories) {
      for (let filter of state.search_filters) {
        if (category.name.toUpperCase().includes(filter)) {
          category.show = true;
          category.strongMatch = true;
          for (let item of category.items) {
            item.show = true;
          }
        }
        if (!category.show)
          for (let item of category.items) {
            if (
              (item.name && item.name.toUpperCase().includes(filter)) ||
              (item.description &&
                item.description.toUpperCase().includes(filter))
            ) {
              item.show = true;
              category.show = true;
            }
          }
        if (category.strongMatch) {
          for (let item of category.items) {
            item.show = true;
          }
        }
      }
    }

    state = { ...state, categories };
  }

  return state;
}
const store = createStore(reducer, applyMiddleware(thunk));




// geoData = { country: "england" };
ReactDOM.render(
  <React.StrictMode>
    
      <Suspense
        fallback={
          <div
            style={{
              position: "fixed",
              right: 2,
              top: "40vh",
              zIndex: 40000000,
              display: "flex",
              flexDirection: "column",
              color: "white",
              backgroundColor: "black",
            }}
          >
            loading...
        </div>
        }
      >
        <Provider store={store}>
          <BrowserRouter>
            <InfoModal></InfoModal>
            <Switch>
            
              <Route
                exact
                path="/scan"
                component={NoQrCodeLandingComponent}
              ></Route>
          
   
          
            <Route
              path="/login"
              component={() => {
                let state = store.getState();
                window.location.href = "https://admin.e-menu.co.edit8.com/login?country="+state.geoData.country+'&currency='+state.geoData.currency+'&language='+state.geoData.language;
                return null;
              }}
            />
           
            <Route exact path="/" component={LoaderInitial}></Route>
            
           
           
            
              <Route path="/" component={App}></Route>
            </Switch>
          </BrowserRouter>
        </Provider>
      </Suspense> 
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
