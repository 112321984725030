import React, { useState } from "react";
import "./HomePage.css";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const HomePage = ({geo}) => {
  
  const state = useSelector(state => state);
  const history = useHistory();
  window.onpopstate = e => {
    window.location.reload(false);

  }
  return (
    <div class="wrapper">
      <div class="header">
        <img
          alt="logo"
          style={{ height: "90%" }}
          src="https://e-menupull.b-cdn.net/logo_e-menu_big.png"
        />
      </div>

      <img
        alt="ad"
        class="demoImage"
        src="https://e-menupull.b-cdn.net/english.png"
      />

          <div style={{ width: "90%", maxWidth: "20em", paddingTop: "1em" }}>
              Protect your customers from COVID-19, allow them to see your menu by scanning QR code or going to your e-menu link!
      <br></br> <br></br> 
      </div>
      <button class="btnStyle" onClick={() => {   window.location.href = "https://admin.e-menu.co.edit8.com/login?country="+geo.country+'&currency='+geo.currency_code+'&language='+geo.language; }}>Create/Edit your menu</button>
          <button class="btnStyle"  onClick={() => history.push("/trendgastro_aerodrom")}>Check DEMO e-menu <br></br>see how it will look like!</button>
   
      {/* <div style={{ width: "90%", maxWidth: "20em", paddingTop: "1em" }}    onClick={() => props.history.push("/businessLogin")}>
              Login to My Account
      </div> */} 
       <button class="btnStyle" onClick={() => {   window.location.href = "https://m.me/restaurant.emenus" }}>Contact us with Messenger now!</button>
      {/* <button class="btnStyle"   onClick={() => history.push("/messenger")}>Send us a message via Messenger!</button> */}

      <div
        style={{
          width: "90%",
          maxWidth: "20em",
          paddingTop: "1em",
          bottom: "1em",
          position: "fixed",
          fontSize: "0.5em",
        }}
      >
        Terms & Conditions Privacy Policy
      </div>
    </div>
  );
};

export default HomePage;
