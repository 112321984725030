import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import constants from "./constants";
import HomePageMacedonian from "./HomePageMacedonian";
import HomePage from "./HomePage";
import HomePageTurkish from "./HomePageTurkish";



var getFirstBrowserLanguage = function () {
    var nav = window.navigator,
    browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
    i,
    language;

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
      for (i = 0; i < nav.languages.length; i++) {
        language = nav.languages[i];
        if (language && language.length) {
          return language;
        }
      }
    }

    // support for other well known properties in browsers
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
      language = nav[browserLanguagePropertyKeys[i]];
      if (language && language.length) {
        return language;
      }
    }

    return null;
  };
const LoaderInitial = () => {
  const dispatch = useDispatch();
  const geoData = useSelector((state) => state.geoData);

  const getCountryFromIpAddress = () => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        // 'Authorization': localStorage.getItem('token')
      },
    };
    Axios.post(
      constants.API.url + ":" + constants.API.port + "/geo_locate",
      {},
      config
    )
      .then((res) => {
        // setActiveMenu(res.data.menus[0]);
        // localStorage.setItem("geoData", res.data);
        dispatch({ type: "SET_GEODATA", payload: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

    
    useEffect(() => {
        getCountryFromIpAddress();
        return () => {
       
        }
    }, [])
    
    console.log('geodataa', geoData);
    let countrySpecificHomePage = null;
    let userLanguage = getFirstBrowserLanguage().substring(0,2);
    
    console.log('user lng:', userLanguage)
    
    if (geoData && geoData.languages) {

        let lang = geoData.languages[0].iso639_1;

        geoData.languages.forEach(language => {
            if (language.iso639_1 === userLanguage)
                lang = language.iso639_1;
                
                
        })
      let geo = { ...geoData, language: lang }
    
        switch (lang) {
            case 'mk':
                countrySpecificHomePage = <HomePageMacedonian geo = {geo}  ></HomePageMacedonian>;
                break;
            case 'en':
                countrySpecificHomePage = <HomePage geo = {geo}></HomePage>;
                break;

                case 'tr':
                  countrySpecificHomePage = <HomePageTurkish geo = {geo}  ></HomePageTurkish>;
                  break;
            default:
                countrySpecificHomePage = <HomePage geo = {geo}></HomePage>;
                break;
        }
    }

  return (
    <>
          {geoData === 'undefined' ? (
              <div
                  style={{
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "black",
                      color: "white",
                  }}
              >
                  Loading Initial...
              </div>
          ) : countrySpecificHomePage
          }
    </>
  );
};

export default LoaderInitial;
