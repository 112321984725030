import React from "react";
import "./HomePage.css";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
const HomePageTurkish = ({geo}) => {
  const history = useHistory();
  const state = useSelector(state => state);
  
  window.onpopstate = e => {
    window.location.reload(false);

  }


  let urlPars = '?country='+geo.country_code.toLowerCase() + '&currency=' + geo.currency_code.toLowerCase() + '&language=' + geo.language.toLowerCase()+'&city=' + geo.city.toLowerCase();

  return (
    <div class="wrapper">
      <div class="header">
        <img
          alt="logo"
          style={{ height: "90%" }}
          src="https://e-menupull.b-cdn.net/logo_e-menu_big.png"
        />
      </div>

      <img
        alt="ad"
        class="demoImage"
        src="https://e-menupull.b-cdn.net/turkish.png"
      />

      <div style={{ width: "90%", maxWidth: "20em", paddingTop: "1em" }}>
      Bedava QR menü oluşturarak kağıt menüleri masalardan kaldırınız. Müşterilerinizi koruyunuz. (30 Eylüle kadar geçerlidir)
              {/* Zastite gi vasite klienti, allow them to see your menu by scanning QR code or going to your e-menu link! */}
      <br></br> <br></br> 
      </div>
          <button class="btnStyle" onClick={() =>  window.location.href = "https://admin.e-menu.co.edit8.com/login"+urlPars}>Yeni menü oluştur / Menüyü değiştir</button>
          <button class="btnStyle"  onClick={() => history.push("/trendgastro_aerodrom")}>DEMO e-menüyü incele<br></br></button>
   
      {/* <div style={{ width: "90%", maxWidth: "20em", paddingTop: "1em" }}    onClick={() => props.history.push("/businessLogin")}>
              Login to My Account
      </div> */}
      {/* <button class="btnStyle"   onClick={() => history.push("/messenger")}>Messenger den mesaj gönder!</button> */}
      <button class="btnStyle" onClick={() => {   window.location.href = "https://m.me/restaurant.emenus" }}>Messenger den mesaj gönder!</button>
      <div
        style={{
          width: "90%",
          maxWidth: "20em",
          paddingTop: "1em",
          bottom: "1em",
          position: "fixed",
          fontSize: "0.5em",
        }}
      >
        Услови за користење & Приватност
      </div>
    </div>
  );
};

export default HomePageTurkish;
